import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "small-content setup-page" }
const _hoisted_2 = { class: "medium-flex" }
const _hoisted_3 = {
  key: 0,
  class: "hcenter-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_setup_module_header = _resolveComponent("setup-module-header")!
  const _component_setup_title_input = _resolveComponent("setup-title-input")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_setup_done_button = _resolveComponent("setup-done-button")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_setup_module_header, {
        title: _ctx.lang.t('title')
      }, null, 8, ["title"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_setup_title_input, {
                label: _ctx.lang.t('titleLabel'),
                module: "checklist"
              }, null, 8, ["label"]),
              _createVNode(_component_ion_card, { class: "h-fit" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_header, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.lang.t("settings")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_card_content, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, { style: {"white-space":"break-spaces"} }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.lang.t("usersCanAdd")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_toggle, {
                                modelValue: _ctx.CreateService.info.config.checklist.userAdd,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.CreateService.info.config.checklist.userAdd) = $event))
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_card, { class: "h-fit" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_header, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.lang.t("tasks")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_card_content, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { position: "floating" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.lang.t("newTask")) + ": ", 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_input, {
                            modelValue: _ctx.taskInput,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.taskInput) = $event)),
                            maxlength: "40",
                            placeholder: _ctx.lang.t('enterTask'),
                            onKeydown: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.addEntry()), ["enter"]))
                          }, null, 8, ["modelValue", "placeholder"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.lang.t("needCount")) + ":", 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_input, {
                            value: _ctx.needInput,
                            type: "number",
                            onIonChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onNeedInput($event))),
                            min: "1",
                            max: "100"
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, { class: "ion-justify-content-end" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_button, {
                            size: "small",
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.addEntry())),
                            disabled: !_ctx.taskInputValid
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, { icon: _ctx.add }, null, 8, ["icon"]),
                              _createTextVNode(" " + _toDisplayString(_ctx.langGeneric.t("add")), 1)
                            ]),
                            _: 1
                          }, 8, ["disabled"])
                        ]),
                        _: 1
                      }),
                      (_ctx.CreateService.info.config.checklist.entries.length <= 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            _createVNode(_component_ion_chip, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.lang.t("noEntries")), 1)
                              ]),
                              _: 1
                            })
                          ]))
                        : (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.CreateService.info.config.checklist
                    .entries, (entry, index) => {
                                return (_openBlock(), _createBlock(_component_ion_item, { key: entry }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(entry.title) + " ", 1),
                                        (entry.need > 1)
                                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                              _createTextVNode("(" + _toDisplayString(entry.need) + " " + _toDisplayString(_ctx.lang.t("needed")) + ")", 1)
                                            ], 64))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_ion_buttons, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_button, {
                                          color: "danger",
                                          onClick: ($event: any) => (_ctx.removeEntry(index))
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_icon, { icon: _ctx.trashBin }, null, 8, ["icon"])
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick"])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ]),
                            _: 1
                          })),
                      _createVNode(_component_setup_done_button, {
                        disabled: !_ctx.validator.valid,
                        error: _ctx.lang.t(_ctx.validator.message)
                      }, null, 8, ["disabled", "error"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}